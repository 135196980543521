module.exports = {
  email: 'meekorusdi@gmail.com',

  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/meekoder',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/meekolyrusdi',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Meekoly',
    },
  ],
};
